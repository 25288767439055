import React from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.min.js';
import "bootstrap/dist/css/bootstrap.min.css";


class NavBar extends React.Component{

    constructor(props){
        super(props);
        this.handleOpen= this.handleOpen.bind(this);
    }

    handleCollapse() {
        var nav = document.getElementById("navbarSupportedContent");
        var btn = document.getElementById("navbarBtn");
        var mobileNav = document.getElementById("mobileNav");
        mobileNav.classList.remove("fullHeight");
        nav.classList.remove("show");
        btn.classList.add("collapsed");
    };

    handleOpen = (event) => {
        var mobileNav = document.getElementById("mobileNav");
        mobileNav.classList.add("fullHeight");
    }

    render(){
        return (
            <nav className={"container mt-2 mb-5"}>

                <div className="nav-items row">
                    <div className="col-12" id="mobileNav">
                        <div className="container-fluid">
                            <button className="navbar-toggler collapsed" id="navbarBtn"  onClick={this.handleOpen} type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li>
                                        <NavLink className="nav-item" to="/" onClick={this.handleCollapse}>Home</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="nav-item" to="/gallery" onClick={this.handleCollapse}>Gallery</NavLink>
                                    </li>
                                    {/*<li>
                                        <NavLink className="nav-item" to="/location" onClick={this.handleCollapse}>When and Where</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="nav-item" to="/activities" onClick={this.handleCollapse}>Activities</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="nav-item" to="/dinner" onClick={this.handleCollapse}>Dinner</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="nav-item" to="/gallery" onClick={this.handleCollapse}>Gallery</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="nav-item" to="/travel" onClick={this.handleCollapse}>Travel Info</NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="nav-item" to="/welcome" onClick={this.handleCollapse}>AI welcome message</NavLink>
                                    </li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={"logo col-12 col-md-5 text-center"}>
                        <a href="/">
                            <h1>Varuna</h1>
                        </a>
                    </div>

                    <div className={"col-12 col-md-7 text-center"}>
                        <ul id={"navigationBar"}>
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>
                                <NavLink to="/gallery">Gallery</NavLink>
                            </li>
                            {/*<li>
                                <NavLink to="/location">When and Where</NavLink>
                            </li>
                            <li>
                                <NavLink to="/activities">Activities</NavLink>
                            </li>
                            <li>
                                <NavLink to="/dinner">Dinner</NavLink>
                            </li>

                            <li>
                                <NavLink to="/travel">Travel Info</NavLink>
                            </li>
                            <li>
                                <NavLink to="/welcome">AI welcome message</NavLink>
                            </li>*/}
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

export default NavBar;