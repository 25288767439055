import React from 'react';
import { lazy, Suspense } from 'react';
import './index.css';
import { Route } from "react-router-dom";
import SlideRoutes from 'react-slide-routes';
import reportWebVitals from './reportWebVitals';

import NavBar from './Components/NavBar';
const Home = lazy(() => import( './pages/Home' ));
const Gallery = lazy(() => import('./pages/Gallery'));
/*const PartyDetails = lazy( () => import('./pages/PartyDetails'));*/
const DinnerMenu = lazy(() => import('./pages/DinnerMenu'));
const AIwelcome = lazy(() => import('./pages/Welcome'));
const Activities = lazy(() => import('./pages/Activities'));
const Location = lazy(() => import('./pages/Location'));
const TravelInfo = lazy(() => import('./pages/TravelInfo'));
const NoPage = lazy( () => import('./pages/NoPage'));


const App = () => {
    return (
        <>
            <NavBar />
            <Suspense fallback={<div className="container text-center">Loading...</div>}>
                <SlideRoutes>
                    <Route index element={<Home />} />
                    <Route path="/" element={<Home />} />
                    <Route path="location" element={<Location />} />
                    <Route path="activities" element={<Activities />} />
                    <Route path="dinner" element={<DinnerMenu />} />
                    <Route path="gallery" element={<Gallery />} />
                    <Route path="welcome" element={<AIwelcome />} />
                    <Route path="travel" element={<TravelInfo />} />
                    <Route path="*" element={<NoPage />} />
                </SlideRoutes>
            </Suspense>
        </>
    );
}

export default App;


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
